<script setup lang="ts">
interface Props {
        timeActivity: _VTI_TYPE_TimeActivity;
        displayTooltip?: boolean;
        format?: string;
        timerClass?: string;
        timerContainerClass?: string;
        timerDaysClass?: string;
    }
    import { ref, onMounted } from 'vue'
    
    const { t } = useTranslation();
    
    const props = defineProps({
  timeActivity: null,
  displayTooltip: { type: Boolean, default: true },
  format: { default: 'HH:mm:ss' },
  timerClass: { default: 'text-base font-semibold text-gray-800 lg:text-xl dark:text-gray-300' },
  timerContainerClass: { default: 'flex items-center space-x-2 text-lg' },
  timerDaysClass: { default: 'text-xs font-thin text-gray-600 dark:text-gray-600 lg:text-sm' }
});
    const getLiveTimerValue = (): string => {
        return props.timeActivity.liveTimer.get().format(props.format, { trim: false });
    };
    const liveTimer = ref(getLiveTimerValue());
    const timerTooltip = computed(() => {
        if (props.timeActivity.isCompleted()) {
            return t('planning.time_activity.live_timer.tooltip.completed', {
                startDate: props.timeActivity.startsAt?.toDayDateString(),
                startTime: props.timeActivity.startsAt?.toShortTimeString(),
                endDate: props.timeActivity.endsAt?.toDayDateString(),
                endTime: props.timeActivity.endsAt?.toShortTimeString(),
            });
        }
        return t('planning.time_activity.live_timer.tooltip.in_progress', {
            startDate: props.timeActivity.startsAt?.toDayDateString(),
            startTime: props.timeActivity.startsAt?.toShortTimeString(),
        });
    });
    function refreshLiveTimer() {
        liveTimer.value = getLiveTimerValue();
    }
    onMounted(() => {
        if (props.timeActivity.isInProgress()) {
            setInterval(refreshLiveTimer, 1000);
        }
    });
</script>

<template>
    <div
        :class="[
            timerContainerClass,
            {
                pulsate: timeActivity.isInProgress(),
            },
        ]"
        :data-tooltip="displayTooltip ? timerTooltip : null"
        data-tooltip-location="left"
    >
        <div
            class="-mt-1"
            v-if="timeActivity.isPaused()"
        >
            <i class="text-sm text-orange-600 fa-regular fa-circle-pause"></i>
        </div>
        <div
            class="-mt-1"
            v-if="timeActivity.isRunning() && !timeActivity.isPaused()"
        >
            <i class="text-sm text-green-600 fa-regular fa-circle-play"></i>
        </div>
        <span
            :class="timerDaysClass"
            v-if="timeActivity.liveTimer.diffInDays() > 0"
        >
            {{ $t('planning.time_activity.live_timer.days_diff', { days: timeActivity.liveTimer.diffInDays() }) }}
        </span>
        <p :class="timerClass">
            {{ liveTimer }}
        </p>
    </div>
</template>
